<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <perseu-card title="Formulário de Oligoelementos">
      <v-form ref="form" slot="content">
        <v-text-field
          label="Nome *"
          v-model="oligoelement.name"
          :rules="[$rules.required]"
        />
        <v-text-field
          label="Ingestão diária"
          v-model="oligoelement.dailyIntake"
        />

        <select-type
          :rules="[$rules.required]"
          v-model="oligoelement.type"
          label="Tipo *"
        />
        <v-textarea
          v-model="oligoelement.information"
          label="Informações *"
          rows="4"
          outlined
          class="mt-3"
          :rules="[$rules.required]"
          maxlength="4000"
          counter
        />
        <v-textarea
          outlined
          label="Explicação sobre"
          v-model.trim="oligoelement.explanation"
          maxlength="2000"
          counter
        />
        <sources-form v-model="oligoelement.sources" />
      </v-form>
      <div slot="actions" class="justify-center">
        <v-btn color="secondary" @click="close">Salvar</v-btn>
      </div>
    </perseu-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FormFlowType",
  components: {
    SourcesForm: () => import("./SourcesForm"),
    SelectType: () => import("./SelectType"),
  },
  data: () => ({
    oligoelement: {
      sources: [],
    },
    dialog: false,
  }),
  methods: {
    create() {
      this.oligoelement = {
        sources: [],
      };
      this.$refs?.form?.reset();
      this.dialog = true;
    },
    edit(oligoelementParam) {
      this.oligoelement = oligoelementParam;
      this.dialog = true;
    },
    close() {
      if (!this.isValid()) return;
      this.$emit("close", this.oligoelement);
      this.dialog = false;
    },
    isValid() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped></style>
